
import { defineComponent, onMounted, ref, reactive } from "vue";
import { getOrganization } from "@/core/services/JwtService";
import { useRouter } from "vue-router";
import { searchGuarantors } from "@/api/guarantor.api";
import { useStore } from "vuex";
import { getDictionary } from "@/api/dictionaries.api";
import PaginationUi from "@/components/ABilling/Pagination.vue";
import useFilters from "../common/useFilters";
import debounce from "lodash.debounce";

export interface Pagination {
  currentPage: number;
  totalPages: number;
  totalCount: number;
  pageSize: number;
}
export interface Data {
  isLoaded: boolean;
  items: any[];
  globalSearch: string;
  pagination: Pagination;
  orderBy: string;
  isDecr: boolean;
  panel: any;
  IsAdvancedOrderBy: boolean;
  dict: {
    relationshipToPatient: { code: string; description: string }[];
    boolList: { code?: boolean; name: string }[];
  };
  showInactive: boolean;
}
export default defineComponent({
  name: "GuarantorList",
  props: ["patientId"],
  components: { PaginationUi },
  setup(props) {
    const store = useStore();
    const router = useRouter();
    const {
      orderList,
      putFiltersToUrl,
      getFiltersFromUrl,
      getFilter,
    } = useFilters();
    orderList.value = [
      {
        name: "Name",
        key: "name",
        isFilter: false,
        keyword: null,
        IsAdvancedOrderBy: true,
      },
      {
        name: "Relationship to Patient",
        key: "relationshipToPatient",
        formType: "relationshipToPatient",
        isFilter: false,
        keyword: null,
      },
      {
        name: "Mobile #",
        key: "mobile",
        isFilter: false,
        keyword: null,
      },
      {
        name: "Guarantor Address",
        key: "address",
        isFilter: false,
        keyword: null,
        IsAdvancedOrderBy: true,
      },
      {
        name: "Email",
        key: "email",
        isFilter: false,
        keyword: null,
      },
      {
        name: "Guarantor Effective Date",
        key: "effectiveDate",
        isFilter: false,
        keyword: null,
        formType: "isDate",
      },
      {
        name: "Guarantor End Date",
        key: "endDate",
        isFilter: false,
        keyword: null,
        formType: "isDate",
      },
    ];

    let organizationId = ref<string | null>("");

    let data = reactive<Data>({
      isLoaded: false,
      items: [],
      globalSearch: "",
      orderBy: "name",
      IsAdvancedOrderBy: true,
      isDecr: false,
      showInactive: false,
      pagination: {
        currentPage: 1,
        totalPages: 0,
        totalCount: 0,
        pageSize: 50,
      },
      panel: {},
      dict: {
        relationshipToPatient: [],
        boolList: [
          { code: true, name: "Yes" },
          { code: false, name: "No" },
          { code: undefined, name: "All" },
        ],
      },
    });

    async function cleanFilter() {
      data.globalSearch = "";
      data.orderBy = "name";
      data.IsAdvancedOrderBy = true;
      data.isDecr = false;
      orderList.value.forEach((item) => {
        item.keyword = null;
      });
      await getAll();
    }

    onMounted(async () => {
      getFiltersFromUrl();
      window.localStorage.removeItem("AddGuarantor");
      organizationId.value = getOrganization();
      data.dict.relationshipToPatient = await getDictionary(10, "");
      await getAll();
      data.isLoaded = true;
    });

    async function selectFilter(header) {
      if (!header.key) {
        return;
      }
      if (data.orderBy == header.key) {
        data.isDecr = !data.isDecr;
      } else {
        data.isDecr = false;
      }
      data.orderBy = header.key;
      data.IsAdvancedOrderBy = header.IsAdvancedOrderBy;

      await search();
    }

    function getSortInfo(key) {
      if (data.orderBy == key) return true;
      return false;
    }

    async function debounceSearch() {
      await debounce(search, useFilters().debounceMs)();
    }

    async function search() {
      data.pagination.currentPage = 1;
      await getAll();
    }

    async function getAll() {
      putFiltersToUrl();

      let order = data.orderBy;
      if (data.isDecr) {
        order = order + " Desc";
      }

      let orderBy: string[] | null = null;
      let advancedOrderBy: string | null = null;

      if (order && !data.IsAdvancedOrderBy) {
        orderBy = [order];
      } else {
        advancedOrderBy = order;
      }
      let request = {
        pageNumber: data.pagination.currentPage,
        pageSize: data.pagination.pageSize,
        orderBy: orderBy,
        advancedOrderBy: advancedOrderBy,
        advancedSearch: {
          fields: orderList.value.map((item) => {
            return item.key;
          })
        },
        PatientId: props.patientId,
        ShowInactive: data.showInactive,
        GlobalSearch: data.globalSearch
      };

      const res = await searchGuarantors(request);
      data.items = res.data;
      data.pagination.currentPage = res.currentPage;
      data.pagination.totalPages = res.totalPages;
      data.pagination.totalCount = res.totalCount;
      data.pagination.pageSize = res.pageSize;
    }

    function selectItem(item) {
      window.localStorage.removeItem("AddGuarantor");
      router.push({
        path: "/patientAccounts/patient/" + props.patientId + "/guarantor/" + item.id,
      });
    }

    function newGuarantor() {
      window.localStorage.removeItem("AddGuarantor");
      router.push({
        path: "/patientAccounts/patient/" + props.patientId + "/guarantor/",
      });
    }

    async function clearSearch(header) {
      header.keyword = null;
      await search();
    }

    async function pageChanged(page) {
      data.pagination.currentPage = page;
      await getAll();
    }

    async function pageSizeChanged(pageSize) {
      data.pagination.pageSize = pageSize;
      data.pagination.currentPage = 1;
      await getAll();
    }

    function getRelationshipToPatient(code) {
      const item = data.dict.relationshipToPatient.find((item) => {
        return item.code == code;
      });
      if (item) {
        return item.description;
      }
      return code;
    }

    return {
      organizationId,
      clearSearch,
      data,
      selectItem,
      orderList,
      newGuarantor,
      debounceSearch,
      search,
      getSortInfo,
      selectFilter,
      cleanFilter,
      pageChanged,
      pageSizeChanged,
      getRelationshipToPatient,
    };
  },
});
